import React from "react";
import { Navigation,Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/autoplay"
export const Banner = () => {
  
  return (
    <div>
      <Swiper
        modules={[Navigation,Autoplay]}
        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        spaceBetween={50}
        slidesPerView={1}
        navigation
          // pagination={{ clickable: true }}
      >
        <SwiperSlide>
         <div className="relative">
         <div className="overflow-hidden">
         <img
            className="w-full sm-h-200px md-h-300px object-contain "
            src={require("../../../assets/images/Website-LG.png")}
            alt="img"
          />
         </div>
          {/* <div className="centered-axis">
           <div className="overflow-hidden">
           <img className=""   src={require("../../../assets/images/Asian-Star_Banner.jpg")} alt="img" />
           </div>
            <h5 className="text-center font-semibold pt-5 ">FROM RAW BEAUTY TO TIMELESS ELEGANCE</h5>
          </div> */}
         </div>
        </SwiperSlide>
        {/* <SwiperSlide>
          <div className="overflow-hidden">
          <img
            className="sm-h-200px w-full md-h-300px object-contain"
            src={require("../../../assets/images/Asian-Star_Banner.jpg")}
            alt="img"
          />
          </div>
        </SwiperSlide>
        <SwiperSlide>
         <div className="overflow-hidden">
         <img
            className="sm-h-200px md-h-300px object-contain"
            src={require("../../../assets/images/Asian-Star_Banner.jpg")}
            alt="img"
          />
         </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="overflow-hidden">
          <img
            className="md-h-300px  sm-h-200px object-contain"
            src={require("../../../assets/images/Asian-Star_Banner.jpg")}
            alt="img"
          />
          </div>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};
