import React from 'react'

export const AboutMission = () => {
  return (
    <div className="lg:px-40 md:px-20 px-5 lg:py-10 md:py-10 py-5">
       <div >
        <h2 className="lg:text-4xl md:text-2xl text-2xl tracking-widest">Our Services</h2>
        {/* <h4 className="">How Can We Help You?</h4> */}
        <p className="pt-3 tracking-widest lg:text-xl md:text-xl text-justify">
          Jewel Art's strong brand image and reputation is evident from its
          prominent clientele in major countries across the globe such as the
          USA, Canada, Spain, Italy, Germany, France, South America, Australia
          and Gulf, whose unwavering faith shows through the repeat business.
        </p>
        <p className="pt-2 tracking-widest lg:text-xl md:text-xl text-justify">
        We
          believe in adding value to our business proposition by providing our
          customers with the best services available in the industry.
          Synchronized production schedules and excellent Quality Control System
          enable us to meet specific requirements of our clients. Constant
          support is provided to our clients in diamond jewellery related
          technical and marketing training.
        </p>
        
      </div>
    </div>
  )
}
