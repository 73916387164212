import { BrowserRouter as Router ,Routes,Route } from "react-router-dom";
import React,{useEffect} from "react";
import { Navbar } from "./view/pages/navbar/Navbar";
import { Footer } from "./view/pages/footer/Footer";
import {Home} from "./view/pages/home/Home"
import { Contact } from "./view/pages/contact/Contact";
import { AboutUs } from "./view/pages/aboutus/AboutUs";
import { Collection } from "./view/pages/collection/Collection";
import { ErrorPage } from "./view/pages/errorpage/ErrorPage";
import { Coming } from "./view/pages/contact/components/Coming";
function App() {
  // const[ userlogin,setUserlogin]=useState()
  return (
    <div className="overflow-hidden">
      <Router>
        <Navbar />
       <div className="lg:pt-44 md:pt-40 pt-20">
       <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/about-us" element={<AboutUs/>}/>
        <Route path="/collection" element={<Collection/>}/>
        <Route path="/contact-us" element={<Contact/>}/>
        <Route path="/comingsoon" element={<Coming/>}/>
        <Route path="*" element={<ErrorPage/>}/>
        </Routes>
       </div>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
