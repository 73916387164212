import React from 'react'
import collectionbanner from "../../../assets/images/collectionbanner1.jpg"
export const CollectionBanner = () => {
  return (
    <div className="relative">
    <div className="overflow-hidden">
    <img className="h-700px w-full brightness-75 sm-h-200px" src={collectionbanner} />
    </div>

    <div className="centered-axis-xy">
      {/* <p className="tracking-widest text-3xl text-justify text-black opacity-60"></p> */}
      {/* <p className="tracking-widest text-center lg:text-3xl md:text-2xl text-xl  pt-5 font-medium">Collectiom Jewellery</p> */}
    </div>
  </div>
  )
}
