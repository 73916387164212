import React from 'react'
import { CollectionBanner } from './components/CollectionBanner'
import { AllCollection } from './components/AllCollection'

export const Collection = () => {
  return (
    <div>
        <CollectionBanner/>
        <AllCollection/>
    </div>
  )
}
