import React from "react";

export const ContactAddress = () => {
  return (
    <div className="lg:flex md:flex items-center lg:px-40 md:px-40 px-5 lg:py-0 md:py-0 py-10">
      {/* <div className='lg:w-1/2 md:w-1/2'>
<p className='lg:text-2xl md:text-xl text-lg text-63666a'>Gala No 19/22, G&J Complex 2,<br/> 3rd floor,SEEPZ,Andheri (East), Mumbai-400096, India.</p>
<div className='flex gap-2 pt-4'>
<p className='lg:text-2xl md:text-xl text-lg text-63666a'>Tel</p>
<a href='telto:+ 9122228291860' className='lg:text-2xl md:text-xl text-lg text-63666a'>+ 9122228291860 /</a>
<a href='fax:+91 2228291779' className='lg:text-2xl md:text-xl text-lg text-63666a'> 61 Fax: +91 2228291779.</a>
</div>
<div className='flex gap-2 pt-2'>
<p className='lg:text-2xl md:text-xl text-lg text-63666a'>E-mail :</p>
<a href='mailto:info@jewelartco.com' className='lg:text-2xl md:text-xl text-lg text-63666a'>info@jewelartco.com</a>
</div>
<div className='pt-8'>
    <h2 className='lg:text-2xl md:text-xl text-lg text-63666a'>Asian Star Compnay Ltd,</h2>
    <div className='flex gap-2 pt-2'>
<p className='lg:text-2xl md:text-xl text-lg text-63666a'>Tel</p>
<p className='lg:text-2xl md:text-xl text-lg text-63666a'>+ 9122228291860 / 61 Fax: +91 2228291779.</p>
</div>
</div>
        </div> */}

      <div
        className="flex justify-center m-auto lg:py-20 md:py-10 py-8"
        data-aos="zoom-in"
      >
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2336.8963713968665!2d72.87414338465184!3d19.125542786519137!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c8189b74c6c5%3A0x3a26d75dcf1e5a31!2sG%20%26%20J%20Complex%2C%20Seepz%20Rd%20A%2C%20Santacruz%20Electronic%20Export%20Processing%20Zone%2C%20Andheri%20East%2C%20Mumbai%2C%20Maharashtra%20400096!5e0!3m2!1sen!2sin!4v1723443111379!5m2!1sen!2sin"
          width="1200"
          height="450"
        
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};
