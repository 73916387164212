import React from 'react'
import { Link } from 'react-router-dom'
export const Earrings = () => {
  return (
    <div>
        <div className="lg:flex md:flex gap-20 items-center lg:px-40 md:px-40 px-5 m-auto lg:py-10 md:py-10 py-10">
        <div className="lg:w-1/2 md:w-1/2 border-primary rounded-md" data-aos="fade-right" data-aos-duration="2000" data-aos-delay="200">
        <div className="mx-10 my-10">

        <img className="m-auto h-270px sm-h-250px" src={require("../../../assets//images/earrings/DM 3D MSE00986_1.jpg")} alt="" />
        </div>
      </div>
      <div className="lg:w-1/2 md:w-1/2 lg:pt-0 md:pt-0 pt-5" data-aos="fade-left" data-aos-duration="2000" data-aos-delay="200">
        <h5 className="lg:text-2xl">The</h5>
        <h2 className="tracking-widest g:text-4xl md:text-2xl text-2xl py-2">Earring</h2>
        <h4 className="tracking-widest lg:text-2xl md:text-xl">Collection</h4>
        <p className="tracking-widest pt-2 g:text-xl md:text-lg text-justify">
        Stud Earrings:Description: Small, simple, and sit directly on the earlobe.
        Examples: Solitaire diamonds, geometric shapes.
        </p>
        <p className="tracking-widest pt-2 g:text-xl md:text-lg text-justify">
        Description: Circular or semi-circular earrings that loop through the earlobe.
        Examples: Classic gold hoops, embellished versions.
        </p>
        <div className="pt-5">
        <Link to="/collection"><button className="bg-primary px-8 py-2 rounded-md text-white">See Collection</button></Link> 
        </div>
      </div>
     
    </div>
    </div>
  )
}
