import React from "react";
import { Link } from "react-router-dom";
export const Rings = () => {
  return (
    <div className="lg:flex md:flex lg:gap-20 md:gap-10 gap-5 sm-columndirection justify-center items-center lg:px-40 md:px-40 px-5 m-auto lg:py-10 md:py-10 py-5">
      <div className="lg:w-1/2 md:w-1/2" data-aos="fade-right" data-aos-duration="2000" data-aos-delay="200">
        <h5 className="lg:text-2xl">The</h5>
        <h2 className="tracking-widest lg:text-4xl md:text-2xl text-2xl py-2">
          Rings
        </h2>
        <h4 className="tracking-widest lg:text-2xl md:text-xl ">Collection</h4>
        <p className="tracking-widest pt-2 lg:text-xl md:text-lg text-justify">
          Engagement or Wedding Ring: Traditional or modern, the design might
          focus on the central gemstone and band style. Custom or Statement
          Ring: Emphasize uniqueness and individuality
        </p>
        <p className="tracking-widest pt-2 lg:text-xl md:text-lg text-justify">
          Fashion Ring: Focus on trends, personal style, and how the ring
          complements other accessories.
        </p>
        <p className="tracking-widest pt-2 lg:text-xl md:text-lg text-justify">
          Custom or Statement Ring: Emphasize uniqueness and individuality
        </p>
        <div className="pt-5">
          <Link to="/collection">
            <button className="bg-primary px-8 py-2 rounded-md text-white">
            See Collection
            </button>
          </Link>
        </div>
      </div>
      <div  className="lg:w-1/2 md:w-1/2 lg:pt-0 md:pt-0 pt-8 border-primary rounded-md"  data-aos="fade-left" data-aos-duration="2000" data-aos-delay="200">
       <div className="mx-10 my-10">
       <img
          className="m-auto h-270px sm-h-250px"
          src={require("../../../assets//images/rings/RG052173.jpg")}
          alt="img"
        />
       </div>
      </div>
    </div>
  );
};
