import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import logo from "../../assets/images/Jewel_Art_Mumbai_Logo_Color_RGB-01.png";
import { Heart, Menu, ShoppingBag } from "lucide-react";
import samplePDF2 from "../../../view/pdf/Jewel Art Web Policy.pdf";
import samplePDF1 from "../../../view/pdf/JA Annual Business Responsibility & OECD Compliance Report.pdf";
import samplePDF3 from "../../../view/pdf/RJCCertificate.pdf";
import samplePDF4 from "../../../view/pdf/JA Stakeholder Grievance Redressal Policy.pdf"
export const Navbar = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showNavbar, setShowNavbar] = useState(false);

  // Function to handle showing and hiding the navbar
  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  // Function to handle closing the navbar when a menu item is clicked
  const handleMenuItemClick = () => {
    setShowNavbar(false);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <nav className="navbar fixed w-full zindex99">
      <div className="m-auto mob-hidden">
        <Link to="/">
          <img
            className="text-white m-auto bg-white object-contain w-190px h-130px"
            src={logo}
            alt="img"
          />
        </Link>
      </div>
      <div className="flex lg:justify-center md:justify-center justify-between items-center px-10">
        <div className="web-hidden">
          <Link to="/">
            <img
              className="text-white bg-white object-contain w-100px h-60px"
              src={logo}
              alt="img"
            />
          </Link>
        </div>
        <div className="menu-icon smflex">
          <Menu onClick={handleShowNavbar} />
        </div>
        <div className={`nav-elements ${showNavbar && "active"} zindex99`}>
          <div className="lg:flex items-center gap-20">
            <ul>
              <li>
                <NavLink to="/" onClick={handleMenuItemClick}>
                  <p className="text-lg tracking-widest">Home</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/about-us" onClick={handleMenuItemClick}>
                  <p className="text-lg tracking-widest">About</p>
                </NavLink>
              </li>
              <li>
                <NavLink to="/collection" onClick={handleMenuItemClick}>
                  <p className="text-lg tracking-widest">Collection</p>
                </NavLink>
              </li>
              <li>
                <div className="dropdown">
                  <p className="text-lg tracking-widest">Policy</p>
                  <div className="dropdown-content">
                    <a href={samplePDF2} target="_blank" rel="noreferrer">
                      <p className="tracking-widest">Web</p>
                    </a>
                    <a href={samplePDF1} target="_blank" rel="noreferrer">
                      <p className="tracking-widest">Compliance Report</p>
                    </a>
                    <a href={samplePDF3} target="_blank" rel="noreferrer">
                      <p className="tracking-widest">RJC Certificate</p>
                    </a>
                    <a href={samplePDF4} target="_blank" rel="noreferrer">
                      <p className="tracking-widest">Stakeholder Grievance Redressal Policy</p>
                    </a>
                  </div>
                </div>
              </li>
              <li>
                <NavLink to="/contact-us" onClick={handleMenuItemClick}>
                  <p className="text-lg tracking-widest">Contact</p>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton className="text-white" />
            <ModalBody className="custpadd0">{/* <Login /> */}</ModalBody>
          </ModalContent>
        </Modal>
      </div>
    </nav>
  );
};
