import React from "react";
import bannerimage from "../../../assets/images/aboutbanner.jpg"
export const Banner = () => {
  return (
    <div>
      <div className="relative">
       <div className="overflow-hidden">
       <img
          className="w-full h-300px sm-h-200px brightness-75"
          src={bannerimage}
        />
       </div>

        <div className="centered-axis-xy">
          <h2 className="lg:text-3xl text-white text-center md:text-2xl text-xl tracking-widest">WE ARE HERE FOR YOU</h2>
          <p className="text-center text-white uppercase lg:text-lg font-medium tracking-widest">Contact Us</p>
        </div>
      </div>
    </div>
  );
};
