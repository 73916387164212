import React from "react";

export const AboutService = () => {
  return (
    <div className="lg:px-40 md:px-20 px-5 lg:py-8 md:py-10 py-8">
      <div>
        <h2 className="lg:text-4xl md:text-2xl text-2xl tracking-widest">
          About Jewel Art
        </h2>
        {/* <h4 className="">How Can We Help You?</h4> */}
        <p className="pt-3 tracking-widest lg:text-xl md:text-xl  text-justify">
          Jewel Art exclusively caters to the international market and crafts
          carefully chosen stones into the most exquisitely designed jewellery
          of high standards. The company strongly focused about market
          expansion, having a record of track of developing contemporary designs
          that cater to a client's specific requirements across all product
          categories.
        </p>
        <p className="pt-2 tracking-widest lg:text-xl md:text-xl text-justify">
          Jewel Art's sophisticated infrastructure harnessed with
          state-of-the-art equipment and technology, specially imported from
          Germany and Japan to produce a range of machine-made and hand-made
          Jewellery.
        </p>
        {/* <p className="pt-2 tracking-widest lg:text-xl md:text-xl text-justify">
          The organization is a professionally managed firm having ISO 9001:2000
          certification, with a goal to deliver the best products on time, every
          time. The company is strongly focused about market expansion, having a
          track record of developing contemporary designs that cater to a
          client's specific requirements across all product categories.
        </p> */}
      </div>
    </div>
  );
};
