import React from 'react'

export const AllCollection = () => {
  return (
    <div className='py-10'>
        <div>
            <h1 className='lg:text-3xl md:text-2xl text-2xl font-semibold text-center'>All Collection</h1>
            <div className='pt-10 '>
                  <h2 className='lg:text-3xl md:text-2xl text-xl bg-primary text-white lg:px-40 md:px-20 px-5 lg:py-2 md:py-2 py-1'>Rings</h2>
                  <div className='grid gap-5 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 '>
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/AMR06596_R.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/DM 3D AJR15708.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/DM 3D AJR35541.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/DM 3D AMR07018.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/DM 3D AMR4352-CU.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/DM 3D RG050717.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/RG052173.jpg")} alt="img" />
                    <img className='lg:w-60 md:w-40 w-auto lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/rings/RG056934-RD-2_W.jpg")} alt="img" />
                  </div>
            </div>
            <div className='pt-10'>
                  <h2 className='lg:text-3xl md:text-2xl text-xl bg-primary text-white lg:px-40 md:px-20 px-5 lg:py-2 md:py-2 py-1'>Earrings</h2>
                  <div className='grid gap-5 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 pt-8 '>
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/earrings/AME01773_W2.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/earrings/AME01811_W.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/earrings/DM 3D MSE00986_1.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/earrings/MSE00527.jpg")} alt="img" />
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR4352-CU.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D RG050717.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG052173.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG056934-RD-2_W.jpg")} alt="img" /> */}
                  </div>
            </div>
            <div className='pt-20'>
                  <h2 className='lg:text-3xl md:text-2xl text-xl bg-primary text-white lg:px-40 md:px-20 px-5 lg:py-2 md:py-2 py-1'>Pendants</h2>
                  <div className='grid gap-5 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 pt-8 '>
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/pendantimage/DM 3D AMP1716-75.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/pendantimage/DM 3D MSP00986_1.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/pendantimage/DM 3D MSP01212.jpg")} alt="img" />
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/earrings/MSE00527.jpg")} alt="img" /> */}
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR4352-CU.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D RG050717.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG052173.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG056934-RD-2_W.jpg")} alt="img" /> */}
                  </div>
            </div>
            <div className='pt-20'>
                  <h2 className='lg:text-3xl md:text-2xl text-xl bg-primary text-white lg:px-40 md:px-20 px-5 lg:py-2 md:py-2 py-1'>Bracelets</h2>
                  <div className='grid gap-5 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 pt-8 '>
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/braclets/DM 3D BR003572CF.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/braclets/DM 3D MSB01343.jpg")} alt="img" />
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/earrings/DM 3D MSE00986_1.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/earrings/MSE00527.jpg")} alt="img" /> */}
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR4352-CU.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D RG050717.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG052173.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG056934-RD-2_W.jpg")} alt="img" /> */}
                  </div>
            </div>
            <div className='pt-10'>
                  <h2 className='lg:text-3xl md:text-2xl text-xl bg-primary text-white lg:px-40 md:px-20 px-5 lg:py-2 md:py-2 py-1'>Bangles</h2>
                  <div className='grid gap-5 lg:grid-cols-4 md:grid-cols-4 grid-cols-2 pt-8 '>
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/bangles/AMBG0402.jpg")} alt="img" />
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/bangles/DM 3D AJBG1362.jpg")} alt="img" />
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AJR35541.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR07018.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR4352-CU.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D RG050717.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG052173.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG056934-RD-2_W.jpg")} alt="img" /> */}
                  </div>
            </div>
            <div className='pt-20'>
                  <h2 className='lg:text-3xl md:text-2xl text-xl bg-primary text-white lg:px-40 md:px-20 px-5 lg:py-2 md:py-2 py-1'>Neckles</h2>
                  <div className='grid lg:grid-cols-4 md:grid-cols-4 grid-cols-2 pt-10'>
                    <img className='w-60 lg:h-60 md:h-40 h-32 m-auto' src={require("../../../assets/images/neckles/DM 3D MSN01359.jpg")} alt="img" />
                    {/* <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AJR15708.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AJR35541.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR07018.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D AMR4352-CU.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/DM 3D RG050717.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG052173.jpg")} alt="img" />
                    <img className='w-60 h-60' src={require("../../../assets/images/rings/RG056934-RD-2_W.jpg")} alt="img" /> */}
                  </div>
            </div>
        </div>
    </div>
  )
}
