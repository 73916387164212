import React from 'react'
import aboutbanner from "../../../assets/images/Jewellery-Repairs.jpg"
export const AboutBanner = () => {
  return (
    <div className="relative">
    <div className="overflow-hidden">
    <img className="h-700px sm-h-200px brightness-75 w-full" src={aboutbanner} />
    </div>

    <div className="centered-axis-about">
      <p className="tracking-widest lg:text-3xl text-justify text-white ">Celebrating a journey of 50+ glorious years</p>
      <p className="tracking-widest text-white text-center  pt-5 font-medium">ABOUT JEWEL ART</p>
    </div>
  </div>
  )
}
